import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGithub,
    faLinkedin,
    faDev
} from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <footer class="w-full flex flex-wrap justify-center mt-4 ">
            <div class="w-full flex justify-center flex-wrap sm:hidden">
                <div class="">
                    <div class="flex items-center w-full">
                        <h5 class="mx-auto font-medium text-base dark:text-dark-purple text-purple">Socials</h5>
                    </div>

                    <div class="social-links w-full flex justify-center">

                        <a href="https://www.linkedin.com/in/jjghali/" class="hover:underline mr-1 hover:underline text-sm">
                            <FontAwesomeIcon icon={faLinkedin} className="mx-1 social-links-icon" />
                            Linkedin
                        </a>

                        <a href="https://dev.to/jjghali" class="hover:underline mr-1 hover:underline text-sm">
                            <FontAwesomeIcon icon={faDev} className="mx-1 social-links-icon" />
                            Dev.to
                        </a>

                        <a href="https://github.com/jjghali" class="hover:underline mr-1 hover:underline text-sm">
                            <FontAwesomeIcon icon={faGithub} className="mx-1 social-links-icon" />
                            Github
                        </a>

                    </div>
                </div>
            </div>
        </footer>
    )
}