import * as React from "react"
import { Helmet } from "react-helmet"

export default function SeoHelmet({ profileData, gravatar, pageTitle }) {
    const lang = profileData.node_locale;
    const url = typeof window !== 'undefined' ? window.location.href : '';


    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={pageTitle}
            titleTemplate={pageTitle}
            meta={[
                { name: 'title', content: pageTitle },
                { name: 'description', content: profileData.headerTitle },
                // OpenGraph meta for Facebook
                { property: "og:type", content: "website" },
                { property: "og:url", content: url },
                { property: "og:title", content: pageTitle },
                { property: "og:description", content: profileData.headerTitle },
                { property: "og:image", content: gravatar.url },
                // Twitter metas
                { property: "twitter:card", content: "summary_large_image" },
                { property: "twitter:url", content: url },
                { property: "twitter:title", content: pageTitle },
                { property: "twitter:description", content: profileData.headerTitle },
                { property: "twitter:image", content: gravatar.url }

            ]}
        />
    )
}