import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAfrica, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { globalHistory } from "@reach/router"
import { Link } from "gatsby";

export default function Header({ profileData, gravatar, lang }) {
    // const profileData = data.profileData;
    // const gravatar = data.gravatar;
    const pathname = globalHistory.location.pathname;
    let languageSwitcher = null;

    if (lang === "fr-CA" || pathname.includes("/fr")) {
        languageSwitcher = (<Link className="hover:underline flex items-center language-selector" to={pathname.replaceAll('/fr', '')}>
            <FontAwesomeIcon icon={faGlobeAmericas} className="mr-2 dark:text-dark-purple text-purple" />
            English
        </Link>)
    } else {
        languageSwitcher = (<Link className="hover:underline flex items-center language-selector" to={'/fr' + pathname}>
            <FontAwesomeIcon icon={faGlobeAfrica} className="mr-2 dark:text-dark-purple text-purple" />
            Français
        </Link>)
    }

    return (
        <header class="mt-2 w-full max-w-6xl md:mx-auto flex justify-between md:flex-no-wrap flex-wrap md:px-8">
            <div class="md:w-1/2 w-full flex flex-wrap">
                <div class="lg:w-auto w-1/6 md:my-auto mr-0 ml-0 align-middle">
                    <img class="profile-pic" width="80px" height="80px" src={gravatar.url} alt={profileData.name} />
                </div>

                <div class="lg:w-9/12 w-5/6 my-auto lg:flex lg:flex-wrap">
                    <h1 class="profile-name  ml-2 md:text-2xl text-2xl">&gt;<span class="mr-2">_
                    </span>{profileData.name}
                    </h1>
                </div>
            </div>

            <div class="md:w-auto w-full flex flex-wrap md:justify-end md:visible invisible md:h-auto h-0">
                <div class="lg:w-auto w-1/6 flex justify-end my-auto md:space-x-4">
                    <a href="/" class="hover:underline flex items-center dark:text-white text-black">
                        <div className="dark:text-dark-purple text-purple">./</div>
                        {profileData.node_locale === "fr-CA" ? "accueil" : "home"}
                    </a>
                    <a href="/devlog" class="hover:underline flex items-center dark:text-white text-black">
                        <div className="dark:text-dark-purple text-purple">./</div>devlog
                    </a>
                    <a href="/aboutme" class="hover:underline flex items-center dark:text-white text-black">
                        <div className="dark:text-dark-purple text-purple">./</div>
                        {profileData.node_locale === "fr-CA" ? "à-propos" : "about"}
                    </a>
                    {languageSwitcher}
                </div>
            </div>
        </header>
    )
}